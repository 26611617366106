@import 'styles/colors.scss';
@import 'styles/login.scss';
@import 'styles/organizations.scss';
@import 'styles/modal.scss';
@import 'styles/file-upload.scss';
@import 'styles/dashboard.scss';
@import 'styles/simple-table.scss';
@import 'styles/steps.scss';
@import 'styles/animations.scss';
@import 'styles/device-detatil.scss';

:root {
  --index: calc(1vw + 1vh);
  --lgSize1: calc(var(--index) * 0.034614);
  --laptopSize1: calc(var(--index) * 0.05581);
}

.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 3s infinite;
    content: '';
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.grey {
  color: $text-color;
}

.custom-actions {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;

  &--small {
    justify-content: flex-end;
  }
}

.text-align-center {
  text-align: center !important;
}

html,
body {
  overflow: hidden;
  height: 100%;
  color: $text-color;
}

#app {
  height: 100%;
}

.psy-application-container {
  display: flex;
  height: 100%;
}

.main-container {
  flex: 1;
  height: 100%;
  width: 100%;
  padding: 18px 20px;
  overflow-y: hidden;
  &:has(> .help-center ) {
    padding: 0;
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    color: $text-color;
    margin-bottom: 1em;
    // border-bottom: 1px solid $border-color;

    .title-description {
      font-weight: 400;
      font-size: 14px;
      padding-bottom: 1em;
      text-transform: none;
    }
  }
}

.main-container-center {
  flex: 1;
  height: 100%;
  width: 100%;
  padding: 36px 40px 0;
  // overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter {
  width: 300px;
  z-index: 400;
  padding-bottom: 1em;
}

.table-container {
  height: calc(100% - 2em - 2em);
  width: 100%;
  border-radius: 8px;
  background-color: white;
}

.svg-inline--fa.fa-times-circle {
  color: $red;
}

.svg-inline--fa.fa-check-circle {
  color: green;
}

.row {
  color: $text-color;
  padding: 10px 10px;
  background-color: white;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.row-no-flex {
    display: block;
  }

  ul {
    width: 100%;
  }
}

.li-row {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 100%;
  color: $text-color;
  text-align: inherit;
}

.btn-primary {
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 2;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 3px;
  font-size: 15px;
  color: white;
  background-color: $dark-highlight;
  border: 1px solid $dark-highlight;

  &:hover {
    color: white;
    background-color: $light-highlight;
    border: 1px solid $light-highlight;
  }
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-img {
  margin-left: 6px;
}

.team-detail-container {
  display: grid;
  height: calc(100% - 72px - 1em);
  grid-auto-rows: 50%;
  gap: 1em;

  .row {
    overflow-y: auto;
  }
}

.create-treatment {
  height: 90%;

  &.scroll {
    overflow: auto;
  }

  .container-login100-form-btn {
    .wrap-login100-form-btn {
      margin: 0;
    }
  }

  .options-container {
    margin-top: 2em;
    min-height: 70%;

    .group-title {
      font-weight: bold;
      font-size: 1.1em;
      padding-bottom: 0.5em;
      border-bottom: 1px solid $border-color;
    }

    .option-group {
      display: flex;
      flex-wrap: wrap;
    }

    .option {
      padding: 1em 5em 1em 0;
      width: 30%;

      .MuiGrid-item {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
      }

      .slider-container {
        margin-left: -12px;
        flex-wrap: nowrap;

        .slider-input {
          flex-basis: 15%;
        }
      }
    }
  }
}

.detail-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(
    auto-fill,
    minmax(calc(25% - 1em), max-content)
  );
  grid-gap: 1em;
}

.detail-grid-3 {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.row-small {
  p {
    word-wrap: break-word;
    word-break: break-all;
    &:last-child {
      max-height: 120px;
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
      text-align: left;
      &::-webkit-scrollbar {
        width: 14px;
      }
      &::-webkit-scrollbar:horizontal {
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px 4px transparent;
      }
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 10px 10px #D3E9F1;
        border: solid 5px transparent;
        border-radius: 14px;
      }
      &::-webkit-scrollbar-thumb:horizontal {
        background: #1F7EA1;
        border: none;
        box-shadow: none;
      }
      @-moz-document url-prefix() {
        scrollbar-color: #D3E9F1 transparent;
        scrollbar-width: thin;
      }
    }
  }
}

.device-configuration-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  .message-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    &.mr {
      margin-top: 30%;
      margin-bottom: 4%;
    }

    &--loading {
      height: 50%;

      button {
        width: 50%;
        background-color: $light-highlight;
      }

      .loading-message {
        padding: 1em 0;
      }
    }

    &--error {
      button {
        width: 75%;
      }

      .error-message {
        padding: 1em 0;
      }
    }
  }
}

.margin-bottom {
  margin-bottom: 1em;
}

.warning-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .warning-icon {
    margin-right: 0.5em;
    color: #ffcc00;
  }
}

.align-items-left {
  align-items: flex-start;
}

button[disabled] {
  opacity: 0.7;
  pointer-events: none;
}

.padding-top {
  padding-top: 0.5em;
}

.multi-filter {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .select-filter {
    width: 100%;
    margin: 1em 1em 0;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.margin-right {
  margin-right: 0.5em;
}

.color-green {
  color: green;
}

textarea {
  border: none;
  background: transparent;
  width: 100%;
  resize: none;
  height: 80px;
}

.MuiPaper-root {
  padding-bottom: 0 !important;
}

.no-pointer {
  pointer-events: none;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .create-treatment .options-container {
    .option {
      width: 49.5%;
      .MuiSlider-root {
        .MuiSlider-markLabel {
          font-size: 14px;
        }
        .MuiSlider-valueLabelOpen .MuiSlider-valueLabelLabel {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .create-treatment .options-container {
    .option {
      width: 100%;
    }
  }
}
