.organizations-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  .title {
    font-weight: 600;
    font-size: 18px;
    color: $text-color;
  }
}

.main-container {
  // background-color: #f4f4f4;
  background-color: #fff;
}

.margin-left {
  margin-left: 1em;
}

.input-wrapper {
  display: flex;
  .wrap-input100 {
    width: 50%;
  }
}

.add-button {
  position: absolute !important;
  bottom: 10px;
  right: 10px;
  background-color: $secondary !important;
  color: white !important;
}

.add-button:hover {
  background-color: $primary !important;
}

* {
  outline: none;
  user-select: none;
}

.create-form {

  &.create-treatment {
    .container-login100-form-btn {
      position: absolute;
      width: 200px;
      z-index: 1;
      bottom: 5%;
      right: 5%;

      .wrap-login100-form-btn {
        width: 100%;
      }
      button {
        width: 200px;
      }
    }
  }
  .container-login100-form-btn {
    
    .wrap-login100-form-btn {
      width: 30%;
    }
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.fa-toggle-on {
  color: #65982b;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
