.metadata-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    width: 50%;
    text-align: left;
  }
  .metadata {
    font-weight: 400;
    color: $text-color;
    opacity:0.8;
  }
}

.chart-container {
  padding: 24px 15px;
  margin-bottom: 1em;
  margin-top: 0.5em;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  .chart-title {
    h3 {
      font-weight: 400;
      color: $text-color;
    }
    
  }
}
