$primary-dark: rgba(180, 180, 180, 0.3);
$brown-alt: #379683;
$border-radius: 8px;

$primary:#2F6690;
$secondary:#2983BF;
$tertiary:#07A2D1;
$dark-highlight: #07A2D1;
$light-highlight: #6ed3ff;
$hover-color: rgba(0, 0, 0, 0.08);

$gray: #6c757d;
$border-color: #ced4da;
$text-color: rgb(92, 92, 92);

$red: #f44336;
$bg-green: #e9f3e6;
$bg-light-red: #F3E7E7;
