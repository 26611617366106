.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #dee2e6;

  button {
    font-size: 1.3em;
    padding: 5px 10px;
    &:hover {
      background-color: $hover-color;
    }
  }
}
.ReactModal__Content.ReactModal__Content--after-open {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.3rem !important;
}

.ReactModal__Overlay {
  background-color: rgba(91, 98, 107, 0.8) !important;
  z-index: 200 !important; //Needs to stay this high because of the MUI DATATABLE z-INDEX
}

.create-form {
  &:not(.create-treatment)
  .MuiGrid-container {
    .MuiGrid-item {
      flex-grow: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}