.file-return {
  margin: 0;
}
.file-return:not(:empty) {
  margin: 1em 0;
}
 .file-return {
  font-style: italic;
  font-size: .9em;
  font-weight: bold;
}
 .file-return:not(:empty):before {
  content: "Selected file: ";
  font-style: normal;
  font-weight: normal;
}