.contact-container {
  box-sizing: border-box;
  padding: 0.5em;
  .module-box-topbar {
    padding: 8px 12px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-end;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid #e9ecef;
  }

  .contact-item {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid #e9ecef;
    box-sizing: border-box;
    font-size: 1em;
    font-weight: 400;
    padding: 0.5em;
    color: #5c5c5c;

    .icon-btn {
      height: 48px;
      display: flex;
      align-items: center;
      width: 48px;
      justify-content: center;
      font-size: 20px;
      color: #6c757d;
      cursor: pointer;
      &:hover {
        background: $hover-color;
      }
    }

    .d-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }

    .col {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 17px;
      padding-left: 17px;
      -webkit-box-flex: 1;
    }

    .col-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
  }
}

.collapsing,
.dropdown,
.dropup {
  position: relative;
}

ul.list {
  display: none;
  position: absolute;
  left: -50px;
  z-index: 999;
  min-width: 10rem;

  margin: 0;
  padding: 10px;
  list-style: none;
  background: #fff;
  color: #333;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -moz-box-shadow: 0 0 5px #999;
  -webkit-box-shadow: 0 0 5px #999;
  box-shadow: 0 0 5px #999;
}
ul.list li {
  padding: 10px;
  border-bottom: solid 1px #ccc;
}
ul.list li:hover {
  background-color: #e9ecef;
}
ul.list li:last-child {
  border: none;
}

.dropdown-menu-show {
  ul.list {
    display: block;
  }
}
