.steps {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .step {
    margin: 1.5em 0;
    font-size: 1.3em;
    width: 450px;

    &-title {
      display: flex;
      justify-content: flex-start;

      div:first-child {
        width: 80px;
      }

      div:last-child {
        width: calc(450px - 80px);
      }
    }

    &-url {
      margin-top: 1em;
      font-size: 0.8em;
      -webkit-user-select: all;
      -moz-user-select: all;
      user-select: all;
      -ms-user-select: all;
    }

    &--button-container {
      display: flex;

      justify-content: flex-end;
      align-items: center;
    }

    &--button-container.center {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 450px) {
  .main-container {
    padding: 0.5em !important;
  }
;
  .main-container-center.steps {
    padding: 0;

    .step {
      width: 100%;

      &-title {
        div:first-child {
          width: 80px;
        }

        div:last-child {
          width: calc(100% - 80px);
        }
      }
    }
  }
}



