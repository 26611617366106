.holding-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
  gap: 4px;
  h3 {
    font-size: 20px;
    color: #5c5c5c;
    font-weight: 600;
  }
  h4 {
    font-size: 18px;
    color: #5c5c5c;
    font-weight: 600;
  }
}

.modal-content {
  width: 45.5vw;
  background: #fff;
  position: absolute;
  overflow: auto;
  outline: none;
  padding: 20px;
  &.device-confirmation {
    width: auto;
  }
}

.device-detail-filter-wrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: fit-content;
  align-items: flex-start;
  margin: 0 auto;
  .select-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
    .device,
    .select-filter {
      width: 240px;
      &.loading {
        & [class*='-placeholder'] {
          text-overflow: ellipsis;
          text-wrap: nowrap;
          overflow: hidden;
        }
      }
      & [class*='-control'] {
        .label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      & [class*='-ValueContainer'],
      & [class*='-control'] > div:first-child {
        position: initial;
        .devices-option {
          display: flex;
          .number {
            position: absolute;
            background: #17121999;
            display: flex;
            padding: 4px 6px;
            border-radius: 8px;
            color: #ececec;
            font-size: 14px;
            left: 50%;
            top: 100%;
            opacity: 0;
            visibility: hidden;
            transform: translateX(-50%);
            transition: 0.3s all ease-in-out;
            &::after {
              content: '';
              position: absolute;
              bottom: 100%;
              left: 50%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: transparent transparent #17121999 transparent;
            }
            .label {
              font-weight: 700;
              margin-right: 5px;
            }
          }
        }
      }
      & [class*='-ValueContainer']:hover,
      & [class*='-control'] > div:first-child:hover {
        .number {
          top: calc(100% + 10px);
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .device-detail-filter-wrapper {
    flex-direction: column;
    .select-wrapper:nth-child(2) {
      width: 100%;
      justify-content: space-between;
    }
  }
}

.select-device-portal,
[class*='-MenuList'] {
  .devices-option {
    display: flex;
    .number {
      margin-left: 10px;
      color: #ccc;
      .label {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .device-detail-filter-wrapper {
    .select-wrapper {
      flex-direction: column;
      gap: 8px;
    }
  }
  .select-device-portal,
  [class*='-MenuList'] {
    .devices-option {
      flex-direction: column;
      .number {
        margin: 0;
      }
    }
  }
}
