@import 'colors.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: unset !important;
  -webkit-user-select: unset !important;
}

/*---------------------------------------------*/
a {
  font-size: 15px;
  line-height: 1.7;
  color: #666666;
  margin: 0;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: $hover-color;
}

/*---------------------------------------------*/
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

ul, li {
  margin: 0;
  list-style-type: none;
}

button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f2f2f2;
}

.wrap-login100 {
  width: 390px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 55px 55px 33px 55px;
}


/*------------------------------------------------------------------
[ Form ]*/

.login100-form {
  width: 100%;
}

.login100-form-title {
  display: block;
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}

.login100-form-title i {
  font-size: 60px;
}

.container-login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 13px;
}

.wrap-login100-form-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }
}

.login100-form-btn {
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  background: $secondary;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-radius: 5px;
}

.cancel {
  margin-left: 1em;
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  background: $secondary;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-radius: 5px;
}


.wrap-login100-form-btn:hover .login100-form-bgbtn {
  left: 0;
}


/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 55px 15px 33px 15px;
  }
}

.input-container {
  width: 100%;
  margin: 1em 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-container-50 {
  width: 50%;
  margin: 0 0.1em;
}
